/*
 * graylog-plugin-logging-alert Source Code
 * Copyright (C) 2018-2020 - Airbus CyberSecurity (SAS) - All rights reserved
 *
 * This file is part of the graylog-plugin-logging-alert GPL Source Code.
 *
 * graylog-plugin-logging-alert Source Code is free software:
 * you can redistribute it and/or modify it under the terms of the
 * GNU General Public License as published by the Free Software Foundation,
 * either version 3 of the License, or (at your option) any later version.
 *
 * This code is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this code.  If not, see <http://www.gnu.org/licenses/>.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Well } from 'components/graylog';

import CommonNotificationSummary from "./CommonNotificationSummary";
import styles from './LoggingAlertSummary.css';

class LoggingAlertSummary extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    notification: PropTypes.object,
    definitionNotification: PropTypes.object.isRequired,
  };

  static defaultProps = {
    notification: {},
  };

  render() {
    const { notification } = this.props;
    return (
        <CommonNotificationSummary {...this.props}>
            <React.Fragment>
              <tr>
                <td>Alert Severity:</td>
                <td>{notification.config.severity || 'No severity for this notification.'}</td>
              </tr>
              <tr>
                <td>Log Content:</td>
                  <Well bsSize="small" className={styles.bodyPreview}>
                    {notification.config.log_body || <em>Empty body</em>}
                  </Well>
              </tr>
              <tr>
                <td>Split Fields:</td>
                <td>{notification.config.split_fields.join(', ') || 'No split fields for this notification.'}</td>
              </tr>
              <tr>
                <td>Aggregation Time Range:</td>
                <td>{notification.config.aggregation_time}</td>
              </tr>
              <tr>
                <td>Alert Tag:</td>
                <td>{notification.config.alert_tag}</td>
              </tr>
                <tr>
                    <td>Single Notification</td>
                    <td>{notification.config.single_notification? 'true' : 'false'}</td>
                </tr>
            </React.Fragment>
        </CommonNotificationSummary>
    );
  }
}

export default LoggingAlertSummary;
